import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input, Label, Form, FormGroup } from "reactstrap";

import CustomTextButton from "../../components/Custom/Buttons/TextButton";
import { transferNftToEmail } from "../../store/actions";

function ToEmail({ Id, handleModal }) {
    const dispatch = useDispatch();
    return (
        <div>
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                })}
                onSubmit={(value) => {
                    value.nftId = Id;
                    handleModal();
                    dispatch(transferNftToEmail(value));
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <div style={{ padding: "1em" }}>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Name (optional)</Label>
                                <Input
                                    key="name"
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                {Boolean(touched.name && errors.name) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.name}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    key="email"
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                                {Boolean(touched.email && errors.email) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.email}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>
                            <div>
                                <CustomTextButton
                                    title="Share"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    disabled={values.email === ""}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
}

export default ToEmail;
