import React from "react";
import { UncontrolledTooltip, Button } from "reactstrap";

const CustomIconButton = ({
    title = "",
    color = "secondary",
    icon = "fas fa-user-plus",
    disabled = false,
    onClick,
    style = {},
    otherProps = {},
    id = "icon-btn",
}) => {
    return (
        <>
            <Button id={id} color={color} onClick={onClick} style={style} {...otherProps} disabled={disabled}>
                <i className={icon} />
            </Button>
            <UncontrolledTooltip placement="bottom" target={id}>
                {title}
            </UncontrolledTooltip>
        </>
    );
};

export default CustomIconButton;
