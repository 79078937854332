import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input, Label, Form, FormGroup } from "reactstrap";

import CustomTextButton from "../../components/Custom/Buttons/TextButton";
import { transferNftToWallet } from "../../store/actions";

function ToWallet({ nftId, handleModal }) {
    const dispatch = useDispatch();

    return (
        <div>
            <Formik
                initialValues={{
                    wallet: "",
                }}
                validationSchema={Yup.object().shape({
                    wallet: Yup.string().max(255).required("Wallet ID is required"),
                })}
                onSubmit={(value) => {
                    value.nftId = nftId;
                    handleModal();
                    dispatch(transferNftToWallet(value));
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <div style={{ padding: "1em" }}>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Wallet ID</Label>
                                <Input
                                    key="wallet"
                                    name="wallet"
                                    type="wallet"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.wallet}
                                />
                                {Boolean(touched.wallet && errors.wallet) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.wallet}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>

                            <div>
                                <CustomTextButton
                                    title="Share"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    disabled={values.wallet === ""}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
}

export default ToWallet;
