/*eslint-disable*/
import React from "react";

function Footer() {
    return (
        <>
            <div className="copy">
                <div className="container">
                    <a href="/">2022 © All Rights Reserved | {process.env.REACT_APP_PROJECT_NAME}</a>
                    <span>
                        <a href="#">
                            <i className="fab fa-telegram" />
                        </a>
                        <a href="#">
                            <i className="fab fa-linkedin" />
                        </a>
                        <a href="#">
                            <i className="fab fa-whatsapp" />
                        </a>
                        <a href="#">
                            <i className="fab fa-facebook-f" />
                        </a>
                    </span>
                </div>
            </div>
        </>
    );
}

export default Footer;
