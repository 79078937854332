export const FETCH_ALL_NFT_TYPES = `query ($pageNumber: Int, $pageSize: Int) {
    list_all_nft_types(page_size: $pageSize, page_number: $pageNumber) {
      message
      data {
        _id
        name
        description
        qr_based
        fields {
          name
          type
        }
      }
      hasMore
    }
  }
  `;
