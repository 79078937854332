/*eslint-disable*/
import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import { Container } from "reactstrap";

import DemoNavbar from "../components/Navbars/DemoNavbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Loader from "../components/Loader";

import { adminRoutes } from "routes.js";
import { isLoggedIn } from "utils";

var ps;

function AdminLayout(props) {
    const mainPanel = useRef();
    const location = useLocation();

    const { isAuthenticated, loading } = useSelector(({ auth: { token }, loading }) => ({
        isAuthenticated: isLoggedIn(token),
        loading,
    }));

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        };
    }, [props]);

    useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);

    return (
        <div className="wrapper">
            <Loader visible={loading} />
            <Sidebar {...props} routes={adminRoutes} bgColor="black" activeColor="warning" />
            <div className="main-panel" ref={mainPanel} style={{ paddingTop: "7em", backgroundColor:"#262734", paddingBottom:"100vh" }}>
                <Container fluid>
                    <DemoNavbar {...props} routes={adminRoutes} />
                    <Switch>
                        {adminRoutes.map((prop, key) => {
                            if (prop.layout === "/app" && isAuthenticated) {
                                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
                            } else {
                                return <Redirect to={"/auth/signin"} key={key} />;
                            }
                        })}
                        <Redirect from="*" to="/app/nfts" />
                    </Switch>
                </Container>
                <Footer fluid />
            </div>
        </div>
    );
}

export default AdminLayout;
