/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// @material-ui/core components
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";

import { signUpCreator, verifyCreator, toggleLoading } from "../../../store/actions";
import SendOtpToVerify from "./sendOtpToVerify";
import RegisterWithOtp from "./registerWithOtp";

function SignUp() {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");

    useEffect(() => {
        dispatch(toggleLoading(false));
    }, []);

    const handleSignUp = async (value) => {
        let result = await dispatch(signUpCreator(value));
        if (result) {
            toast.success("OTP sent successfully");
            setEmail(value.email);
        }
    };

    const handleVerify = (value) => {
        dispatch(verifyCreator({ email: email, otp: value.otp }));
    };

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    {email === "" ? (
                        <SendOtpToVerify onSubmit={handleSignUp} />
                    ) : (
                        <RegisterWithOtp onSubmit={handleVerify} />
                    )}
                </Grid>
            </Grid>
        </>
    );
}

export default SignUp;
