/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Navbar, Container, UncontrolledTooltip } from "reactstrap";

import { logoutUser } from "store/actions";

function AdminNavbar(props) {
    const [isOpen, setIsOpen] = useState(false);
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    const [color, setColor] = useState("transparent");
    const sidebarToggle = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    // const toggle = () => {
    //     if (isOpen) {
    //         setColor("transparent");
    //     } else {
    //         setColor("dark");
    //     }
    //     setIsOpen(!isOpen);
    // };

    // const dropdownToggle = (e) => {
    //     setDropdownOpen(!dropdownOpen);
    // };

    const getBrand = () => {
        let brandName = "Default Brand";
        props.routes.map((prop, key) => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                brandName = prop.name;
            }
            return null;
        });
        return brandName;
    };

    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
    };
    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && isOpen) {
            setColor("dark");
        } else {
            setColor("transparent");
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updateColor.bind(this));
    });

    useEffect(() => {
        if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }
    }, [location]);

    return (
        // add or remove classes depending if we are on full-screen-maps page or not
        <Navbar
            color={props.location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color}
            expand="lg"
            className={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "navbar-absolute fixed-top"
                    : "navbar-absolute fixed-top " + (color === "transparent" ? "navbar-transparent " : "")
            }
            style={{border:0}}
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button type="button" ref={sidebarToggle} className="navbar-toggler" onClick={() => openSidebar()}
                        style={{color:"white"}}>
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    {/* <NavbarBrand href="/">{getBrand()}</NavbarBrand> */}
                </div>
                {/* <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler> */}
                <i className="fas fa-sign-out-alt fa-lg" id="logout-icon" onClick={() => dispatch(logoutUser())} 
                style={{color:"white"}}/>
                <UncontrolledTooltip placement="bottom" target="logout-icon">
                    Logout
                </UncontrolledTooltip>
            </Container>
        </Navbar>
    );
}

export default AdminNavbar;
