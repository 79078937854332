/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// reactstrap components
import { Button, Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import CustomModal from "../../components/Custom/Modal";
import RedeemModalContent from "./redeemModalContent";
import ProfileUpdateModal from "./profileUpdateModal";

import { listUserProfile, resetUserProfileData, listHomepageData, resetHomepageData } from "../../store/actions";
import { convertToSOL } from "../../utils";

function UserProfile() {
    const dispatch = useDispatch();

    const [text, setText] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [profileEditModal, setProfileEditModal] = useState(false);

    const { data, homepageData } = useSelector(({ users: { profileData, homepageData } }) => ({
        data: profileData,
        homepageData,
    }));

    useEffect(() => {
        dispatch(resetUserProfileData());
        dispatch(resetHomepageData());
        dispatch(listHomepageData());
        dispatch(listUserProfile());
    }, []);

    const handleModalClose = () => {
        setShowModal(false);
    };

    const copy = async () => {
        await navigator.clipboard.writeText(data?.wallet_address);
        setText(data?.wallet_address);
        toast.success("Copied to clipboard");
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="6">
                        <Card className="card-user">
                            <div className="image">
                                {/* <img
                  alt="..."
                  src={require("assets/img/damir-bosnjak.jpg").default}
                /> */}
                            </div>
                            <CardBody>
                                <div className="author">
                                    <img
                                        alt="..."
                                        className="avatar border-gray"
                                        src={data?.img ?? require("assets/img/user/undraw_profile_pic_ic5t.png").default}
                                        style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}
                                    />
                                    <h4 className="title">
                                        {data?.name} &nbsp;&nbsp;
                                        <i
                                            className="fas fa-edit"
                                            title="Update"
                                            style={{
                                                cursor: "pointer",
                                                fontSize: "20px",
                                                color: "grey",
                                            }}
                                            onClick={() => setProfileEditModal(true)}
                                        />{" "}
                                    </h4>

                                    <h5 className="title">{data?.email}</h5>
                                    <h6 className="title"> Wallet Address: </h6>
                                    <p
                                        style={{
                                            backgroundColor: "#dcddde",
                                            borderRadius: "20px",
                                            padding: "7px",
                                        }}
                                    >
                                        <strong>{data?.wallet_address} </strong> &nbsp;
                                        <i
                                            className={text ? "fa fa-copy" : "far fa-copy"}
                                            title="Copy to clipboard"
                                            style={{ float: "right", cursor: "pointer", margin: "2px" }}
                                            onClick={copy}
                                        />
                                    </p>
                                    {/* <h5 className="title">
                      Wallet Balance:{" "}
                      {data?.acc_balance ? convertToSOL(data?.acc_balance) : 0}
                    </h5> */}
                                    {/* <h5 className="title">
                      Funq Coins: {data?.funq_credits ?? 0}
                    </h5> */}
                                    <p>
                                        <Button onClick={() => setShowModal(true)} disabled={data?.acc_balance < 0.02}>
                                            Transfer
                                        </Button>
                                    </p>
                                    {/* <p className="description">@chetfaker</p> */}
                                </div>
                                {/* <p className="description text-center">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                    has been the industry's standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type specimen book.
                                </p> */}
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="button-container">
                                    <Row>
                                        {/* <Col className="ml-auto" lg="3" md="6" xs="6">
                      <h5>
                        12 <br />
                        <small>NFTs</small>
                      </h5>
                    </Col> */}
                                        {/* <Col className="ml-auto mr-auto" lg="4" md="6" xs="6">
                      <h5>
                        2GB <br />
                        <small>Used</small>
                      </h5>
                    </Col>
                    <Col className="mr-auto" lg="3">
                      <h5>
                        24,6$ <br />
                        <small>Spent</small>
                      </h5>
                    </Col> */}
                                    </Row>
                                </div>
                            </CardFooter>
                        </Card>
                        {/* <Card>
              <CardHeader>
                <CardTitle tag="h4">Team Members</CardTitle>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled team-members">
                  <li>
                    <Row>
                      <Col md="2" xs="2">
                        <div className="avatar">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={
                              require("assets/img/faces/ayo-ogunseinde-2.jpg")
                                .default
                            }
                          />
                        </div>
                      </Col>
                      <Col md="7" xs="7">
                        DJ Khaled <br />
                        <span className="text-muted">
                          <small>Offline</small>
                        </span>
                      </Col>
                      <Col className="text-right" md="3" xs="3">
                        <Button
                          className="btn-round btn-icon"
                          color="success"
                          outline
                          size="sm"
                        >
                          <i className="fa fa-envelope" />
                        </Button>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="2" xs="2">
                        <div className="avatar">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={
                              require("assets/img/faces/joe-gardner-2.jpg")
                                .default
                            }
                          />
                        </div>
                      </Col>
                      <Col md="7" xs="7">
                        Creative Tim <br />
                        <span className="text-success">
                          <small>Available</small>
                        </span>
                      </Col>
                      <Col className="text-right" md="3" xs="3">
                        <Button
                          className="btn-round btn-icon"
                          color="success"
                          outline
                          size="sm"
                        >
                          <i className="fa fa-envelope" />
                        </Button>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="2" xs="2">
                        <div className="avatar">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={
                              require("assets/img/faces/clem-onojeghuo-2.jpg")
                                .default
                            }
                          />
                        </div>
                      </Col>
                      <Col className="col-ms-7" xs="7">
                        Flume <br />
                        <span className="text-danger">
                          <small>Busy</small>
                        </span>
                      </Col>
                      <Col className="text-right" md="3" xs="3">
                        <Button
                          className="btn-round btn-icon"
                          color="success"
                          outline
                          size="sm"
                        >
                          <i className="fa fa-envelope" />
                        </Button>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </CardBody>
            </Card> */}
                    </Col>
                    <Col>
                        <Row>
                            <Col lg="6" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="fas fa-cubes" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Own NFTs</p>
                                                    <CardTitle tag="p">{homepageData?.total_own_nfts}</CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats"></div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="6" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="fa fa-share" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Sold NFTs</p>
                                                    <CardTitle tag="p">{homepageData?.total_sold_nfts ?? 0}</CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats"></div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="fa fa-wallet" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Wallet Balance</p>
                                                    <CardTitle tag="p">
                                                        {data?.acc_balance ? convertToSOL(data?.acc_balance) : 0}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats"></div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="6" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="fa fa-coins" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Coins</p>
                                                    <CardTitle tag="p">{data?.funq_credits ?? 0}</CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats"></div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Edit Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Company (disabled)</label>
                        <Input
                          defaultValue="Creative Code Inc."
                          disabled
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          defaultValue="michael23"
                          placeholder="Username"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input placeholder="Email" type="email" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          defaultValue="Chet"
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          defaultValue="Faker"
                          placeholder="Last Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          defaultValue="Melbourne, Australia"
                          placeholder="Home Address"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          defaultValue="Melbourne"
                          placeholder="City"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          defaultValue="Australia"
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input placeholder="ZIP Code" type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          type="textarea"
                          defaultValue="Oh so, your weak rhyme You doubt I'll bother, reading into it"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Update Profile
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col> */}
                </Row>
                <CustomModal
                    title="Transfer"
                    visible={showModal}
                    handleClose={handleModalClose}
                    content={<RedeemModalContent handleModal={handleModalClose} />}
                />
                <CustomModal
                    title="Update Username"
                    visible={profileEditModal}
                    handleClose={() => setProfileEditModal(false)}
                    content={<ProfileUpdateModal handleModal={() => setProfileEditModal(false)} />}
                />
            </div>
        </>
    );
}

export default UserProfile;
