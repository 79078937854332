import { post } from "../../services";

export const authorizedPost = async (query, variables = {}) => {
    try {
        let apiResponse = await post("", {
            query: query,
            variables: variables,
        });

        if (apiResponse.data.customStatus) {
            return {
                status: true,
                data: apiResponse.data.data,
            };
        }
        return { status: false, data: {} };
    } catch (err) {
        return { status: false, data: {} };
    }
};
