/*eslint-disable*/
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input, Label, Form, FormGroup, FormText } from "reactstrap";
import { toast } from "react-toastify";

import CustomTextButton from "../../../components/Custom/Buttons/TextButton";
import { getBase64 } from "../../../utils";
import { createNewNft, toggleLoading } from "../../../store/actions";

const CreateNewNft = ({ handleModal, handleInfoModal, type }) => {
    const dispatch = useDispatch();

    const [base64Img, setBase64Img] = useState("");
    const [imgPath, setImgPath] = useState("");

    const imgToBase64 = async (file) => {
        let data = await getBase64(file);
        setBase64Img(data);
    };

    const handleCreateNFT = (data) => {
        dispatch(toggleLoading(true));
        if (type) {
            if (!type?.qr_based) {
                if (!base64Img) {
                    dispatch(toggleLoading(false));
                    return toast.error("Please select a file");
                }
                data.file = base64Img;
            }
        } else {
            if (!base64Img) {
                dispatch(toggleLoading(false));
                return toast.error("Please select a file");
            }
            data.file = base64Img;
        }
        handleModal();
        dispatch(createNewNft(data));
        return handleInfoModal();
    };

    const initialValues = () => {
        let values = {};
        if (type) {
            type.fields.map((e) => {
                values[e?.name] = "";
            });
        } else {
            values.name = "";
            values.description = "";
        }
        return values;
    };

    return (
        <div>
            <Formik
                initialValues={initialValues()}
                onSubmit={async (value) => {
                    // value.artwork = base64Img;
                    if (type) {
                        value.type_id = type?._id;
                    }
                    await handleCreateNFT(value);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <div style={{ padding: "1em" }}>
                        <Form onSubmit={handleSubmit}>
                            {type ? (
                                <>
                                    {type?.fields.map((e, i) => {
                                        return (
                                            <FormGroup key={i}>
                                                <Label style={{ textTransform: "capitalize" }}>
                                                    {e?.name.replaceAll("_", " ")}
                                                </Label>
                                                <Input
                                                    key={e?.name}
                                                    name={e?.name}
                                                    type={e?.type}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values[e?.name]}
                                                />
                                                {Boolean(touched[e?.name] && errors[e?.name]) ? (
                                                    <div
                                                        style={{
                                                            display: "block",
                                                            marginLeft: "10px",
                                                            color: "red",
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {errors[e?.name]}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </FormGroup>
                                        );
                                    })}
                                    {!type?.qr_based ? (
                                        <FormGroup key="artwork">
                                            <Label>File</Label>
                                            <Input
                                                type="file"
                                                name="artwork"
                                                onChange={(e) => {
                                                    setImgPath(e.target.value);
                                                    imgToBase64(e.target.files);
                                                }}
                                                onBlur={handleBlur}
                                                accept="image/*"
                                            />
                                            <FormText color="muted">Select file for converting to NFT</FormText>
                                        </FormGroup>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <FormGroup key="name">
                                        <Label style={{ textTransform: "capitalize" }}>Name</Label>
                                        <Input
                                            key="name"
                                            name="name"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {Boolean(touched.name && errors.name) ? (
                                            <div
                                                style={{
                                                    display: "block",
                                                    marginLeft: "10px",
                                                    color: "red",
                                                    fontSize: 12,
                                                }}
                                            >
                                                {errors.name}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>

                                    <FormGroup key="description">
                                        <Label style={{ textTransform: "capitalize" }}>description</Label>
                                        <Input
                                            key="description"
                                            name="description"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                        />
                                        {Boolean(touched.description && errors.description) ? (
                                            <div
                                                style={{
                                                    display: "block",
                                                    marginLeft: "10px",
                                                    color: "red",
                                                    fontSize: 12,
                                                }}
                                            >
                                                {errors.description}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>

                                    <FormGroup key="artwork">
                                        <Label>File</Label>
                                        <Input
                                            type="file"
                                            name="artwork"
                                            onChange={(e) => {
                                                setImgPath(e.target.value);
                                                imgToBase64(e.target.files);
                                            }}
                                            onBlur={handleBlur}
                                            accept="image/*"
                                        />
                                        <FormText color="muted">Select file for converting to NFT</FormText>
                                    </FormGroup>
                                </>
                            )}

                            <div>
                                <CustomTextButton
                                    title="Create NFT"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default CreateNewNft;
