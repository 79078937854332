/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { Card, Grid, Switch } from "@material-ui/core";

import CustomTable from "../../components/Custom/Tables/CustomTable";
import NftCard from "../../components/Cards/NftCard";
import CustomTextButton from "../../components/Custom/Buttons/TextButton";

import { listAllOwnNfts, resetNftsData, toggleViewMode } from "../../store/actions";
import { history } from "../../utils";

const OwnNftList = () => {
    const dispatch = useDispatch();

    const { nftsData, showViewMore, viewMode } = useSelector(({ nfts: { list, showViewMore }, txns: { isCardView } }) => ({
        nftsData: list,
        showViewMore,
        viewMode: isCardView,
    }));

    useEffect(() => {
        dispatch(resetNftsData());
        fetchMoreNfts();
    }, []);

    const fetchMoreNfts = () => {
        dispatch(listAllOwnNfts());
    };

    const handleChange = (event) => {
        dispatch(toggleViewMode(event.target.checked));
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card style={{ borderRadius: "15px", marginBottom: "20px" }}>
                            <Grid item style={{ padding: "15px", fontSize: "20px" }}>
                                Card view
                                <Switch
                                    checked={viewMode}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                    color="primary"
                                />
                            </Grid>
                        </Card>
                        {!viewMode ? (
                            <CustomTable
                                title="Own NFTs"
                                columns={[
                                    {
                                        title: "Actions",
                                        render: (rowData) => (
                                            <CustomTextButton
                                                title="open"
                                                otherProps={{ size: "sm" }}
                                                onClick={() => history.push(`/app/nft/${rowData._id}`)}
                                            />
                                        ),
                                    },
                                    { title: "Name", field: "name" },
                                    { title: "Description", field: "description" },
                                    { title: "NFT ID", field: "nft_id" },
                                    {
                                        title: "Artwork",
                                        field: "art_url",
                                        render: (rowData) => <img src={rowData?.art_url} width={40} height={40} />,
                                    },
                                ]}
                                data={nftsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        ) : (
                            <NftCard
                                showStatus={false}
                                data={nftsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default OwnNftList;
