/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Card, Jumbotron } from "reactstrap";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
//styles
import "./img-styles.css";

import CustomBackButton from "../../components/Custom/Buttons/BackButton";
import { listNftDetails, resetNftDetailsData } from "../../store/actions";
import CustomModal from "../../components/Custom/Modal";
import CustomTextButton from "../../components/Custom/Buttons/TextButton";
import DeleteDialogBox from "./deleteDialogBox";
import ShareNft from "./shareNft";

const NftDetails = () => {
    const dispatch = useDispatch();
    const { nftId } = useParams();

    const [showImg, setShowImg] = useState(true);
    const [showDialogBox, setShowDialogBox] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { nftDetails } = useSelector(({ nfts: { details } }) => ({
        nftDetails: details,
    }));

    useEffect(() => {
        dispatch(resetNftDetailsData());
        dispatch(listNftDetails({ id: nftId }));
    }, []);

    const handleModal = () => {
        setShowModal(false);
    };

    const modalContent = () => {
        if (showImg) {
            return (
                <div style={{ padding: "1em", display: "flex", justifyContent: "center" }}>
                    <img src={nftDetails?.art_url} />
                </div>
            );
        } else {
            return <ShareNft Id={nftDetails?._id} nftId={nftDetails?.nft_id} handleModal={handleModal} />;
        }
    };

    const handleShareButton = () => {
        if (nftDetails?.is_for_sale) {
            return toast.info("Not able to transfer artwork added to sales");
        }

        if (nftDetails?.status === "sold") {
            return toast.info("Not able to transfer sold artwork");
        }

        if (nftDetails?.status === "pending") {
            return toast.info("Not able to transfer pending artwork");
        }

        if (nftDetails?.status === "transferred") {
            return toast.info("Not able to share transferred artwork");
        }

        setShowImg(false);
        setShowModal(true);
    };

    const handleDialogClose = () => {
        setShowDialogBox(false);
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container>
                                    <Grid item>
                                        <CustomBackButton />
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} xs={12} sm={12} className="container">
                        <img src={nftDetails?.art_url} className="image" style={{ width: "100%" }} />
                        <div className="middle">
                            <div
                                className="text"
                                onClick={() => {
                                    setShowImg(true);
                                    setShowModal(true);
                                }}
                            >
                                View Original
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={9} lg={9} md={9} xs={12} sm={12}>
                        <Jumbotron style={{ height: "100%" }}>
                            <h1 className="display-5">{nftDetails?.name}</h1>
                            <p className="lead">{nftDetails?.description}</p>
                            <hr className="my-2" />
                            <p>NFT ID: {nftDetails?.nft_id}</p>
                            <p style={{ textTransform: "capitalize" }}>Status: {nftDetails?.status}</p>
                            <p style={{ textTransform: "capitalize" }}>Owner: {nftDetails?.owner_id?.name}</p>
                            <p style={{ textTransform: "capitalize" }}>Type: {nftDetails?.type_id?.name ?? "-"}</p>
                            {nftDetails?.type_id
                                ? nftDetails?.type_id?.fields
                                      .filter((e) => e?.name !== "name")
                                      .map((e, i) => {
                                          if (nftDetails[e?.name] && nftDetails[e?.name] !== "") {
                                              return (
                                                  <p key={i} style={{ textTransform: "capitalize" }}>
                                                      {e?.name.replaceAll("_", " ")}: {nftDetails[e?.name]}
                                                  </p>
                                              );
                                          }
                                      })
                                : null}
                            <>
                                <Grid container spacing={1} justifyContent="flex-start">
                                    <Grid item>
                                        <CustomTextButton title="Share Artwork" onClick={() => handleShareButton()} />
                                    </Grid>
                                    <Grid item>
                                        <CustomTextButton
                                            title="Delete"
                                            onClick={() => setShowDialogBox(true)}
                                            disabled={
                                                nftDetails.status === "transferred" ||
                                                nftDetails.status === "for sales" ||
                                                nftDetails.status === "sold"
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </>
                            <p className="lead"></p>
                        </Jumbotron>
                    </Grid>
                </Grid>
            </Container>
            <CustomModal
                title={showImg ? "" : "Share Artwork"}
                visible={showModal}
                content={modalContent()}
                handleClose={() => setShowModal(false)}
            />
            <DeleteDialogBox open={showDialogBox} handleClose={handleDialogClose} nftId={nftId} dispatch={dispatch} />
        </>
    );
};

export default NftDetails;
