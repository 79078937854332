import React from "react";
import { history } from "utils";
import Footer from "../../views/shared/footer";
import Navbar from "../../views/shared/navbar";

const buttonStyle = {
    marginLeft: "18px",
    backgroundColor: "#F9CA26",
    color: "black",
    border: 0,
};

function Landing() {
    return (
        <div>
            <Navbar />
            {/* ***************************** Banner Starts Here **********************************/}
            <section
                className="container-fluid banner-container"
                style={{ backgroundColor: "rgb(26,27,40)", color: "white" }}
            >
                <div className="container">
                    <div className="row banner-row">
                        <div className="col-md-6 banner-txt">
                            <h1>
                                TokenList is a low code platform for entrepreneurs to launch their business on web3 and go
                                global
                            </h1>
                            <p>
                                Real-world assets can now be tokenised through smart contract. Our platform can be used to
                                tokenise securities, properties, hospitality, travel, entertainment, jewellery, membership,
                                loyalty points & coupons, merchandise etc.
                            </p>
                            <div className="btn-row row">
                                <button
                                    onClick={() => window.open("https://thetokenlist.com/auth/listings", "_self")}
                                    className="btn btn-outline-primary clr"
                                    style={{ marginLeft: "18px", color: "#F9CA26" }}
                                >
                                    View marketplace
                                </button>

                                <button
                                    onClick={() => history.push("/auth/signup")}
                                    className="btn btn-primary clr"
                                    style={buttonStyle}
                                >
                                    Sign Up here
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 banner-img">
                            <img src="assets/images/undraw_accept_tasks.svg" alt="" />
                        </div>
                        <br />
                        &nbsp;
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Landing;
