import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { projectName } from "helpers";

var ps;

function Sidebar(props) {
    const sidebar = React.useRef();
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });
    return (
        <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor} style={{border:0}} >
            <div className="logo" style={{ display: "flex", justifyContent: "center" }}>
                <a href="/" className="simple-text logo-normal" style={{color:"#F9CA26"}}>
                    {projectName}
                </a>
            </div>
            <div className="sidebar-wrapper" ref={sidebar}>
                <Nav >
                    {props.routes.map((prop, key) => {
                        if (prop.visibility) {
                            return (
                                <li className={activeRoute(prop.path) + (prop.pro ? " active-pro" : "")} key={key}>
                                    <NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active">
                                        <i className={prop.icon}></i>
                                        <p>{prop.name}</p>
                                    </NavLink>
                                </li>
                            );
                        } else {
                            return null;
                        }
                    })}
                </Nav>
            </div>
        </div>
    );
}

export default Sidebar;
