import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Input, Label, Form, FormGroup } from "reactstrap";
import CustomTextButton from "../../components/Custom/Buttons/TextButton";
import { updateUserProfile } from "../../store/actions";

const ProfileUpdateModal = ({ handleModal }) => {
    const dispatch = useDispatch();

    return (
        <>
            <Formik
                initialValues={{
                    name: "",
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required("Username is required"),
                })}
                onSubmit={(value) => {
                    handleModal();
                    dispatch(updateUserProfile(value));
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <div style={{ padding: "1em" }}>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Username</Label>
                                <Input
                                    key="name"
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                {Boolean(touched.name && errors.name) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.name}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>
                            <div>
                                <CustomTextButton
                                    title="Update"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    disabled={values.name === ""}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default ProfileUpdateModal;
