import { toast } from "react-toastify";
import { unAuthorizedPost } from "../../services";
import { history } from "../../utils";
import { LOGIN_SUCCESS, LOGOUT, TOGGLE_LOADING } from "../types";
import { LOGIN_WITH_OTP, SEND_OTP, SIGNUP_CREATOR, VERIFY_CREATOR } from "../../graphql";

export const signUpCreator = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: SIGNUP_CREATOR,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            // let {
            //     send_otp: { message },
            // } = apiResponse.data.data;
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};

export const verifyCreator = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: VERIFY_CREATOR,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            // let {
            //     login_with_otp: { token },
            // } = apiResponse.data.data;

            // dispatch({
            //     type: LOGIN_SUCCESS,
            //     payload: token,
            // });
            toast.success("Registered successfully");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        history.push("/auth/login");
    };
};

export const sendOtp = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: SEND_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            // let {
            //     send_otp: { message },
            // } = apiResponse.data.data;
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};

export const loginWithOtp = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: LOGIN_WITH_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                login_with_otp: { token },
            } = apiResponse.data.data;

            dispatch({
                type: LOGIN_SUCCESS,
                payload: token,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        history.push("/app/dashboard");
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
    };
};
