import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Input, Label, Form, FormGroup } from "reactstrap";
import CustomTextButton from "../../components/Custom/Buttons/TextButton";
import { redeemWalletAmount } from "../../store/actions";

const RedeemModalContent = ({ handleModal }) => {
    const dispatch = useDispatch();

    return (
        <>
            <Formik
                initialValues={{
                    destWalletId: "",
                    amount: 0,
                }}
                validationSchema={Yup.object().shape({
                    destWalletId: Yup.string().max(255).required("Wallet address is required"),
                    amount: Yup.number().min(0.01).required("Number is required"),
                })}
                onSubmit={(value) => {
                    handleModal();
                    dispatch(redeemWalletAmount(value));
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <div style={{ padding: "1em" }}>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Wallet Address</Label>
                                <Input
                                    key="destWalletId"
                                    name="destWalletId"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.destWalletId}
                                />
                                {Boolean(touched.destWalletId && errors.destWalletId) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.destWalletId}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>Redeem Amount</Label>
                                <Input
                                    key="amount"
                                    name="amount"
                                    type="number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.amount}
                                />
                                {Boolean(touched.amount && errors.amount) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.amount}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>
                            <div>
                                <CustomTextButton
                                    title="Redeem"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    disabled={values.destWalletId === "" || values.amount === 0}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default RedeemModalContent;
