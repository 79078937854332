/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { Card, FormControl, Grid, MenuItem, Select, Switch } from "@material-ui/core";

import CustomTable from "../../components/Custom/Tables/CustomTable";
import TxnsCard from "../../components/Cards/txnsCard";

// import CustomTextButton from "../../components/Custom/Buttons/TextButton";
// import { history } from "../../utils";
import { resetTxnsData, listAllTxns, toggleViewMode } from "../../store/actions";
// import { convertToSOL } from "../../utils";

const TxnsList = () => {
    const dispatch = useDispatch();
    const [tag, setTag] = useState("");

    const { txnsData, showViewMore, viewMode } = useSelector(({ txns: { list, showViewMore, isCardView } }) => ({
        txnsData: list,
        showViewMore,
        viewMode: isCardView,
    }));

    useEffect(() => {
        dispatch(resetTxnsData());
        fetchMoreNfts();
    }, [tag]);

    const fetchMoreNfts = () => {
        dispatch(listAllTxns({ tag: tag }));
    };

    const handleChange = (event) => {
        dispatch(toggleViewMode(event.target.checked));
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card style={{ borderRadius: "15px", marginBottom: "20px" }}>
                            <Grid container>
                                <Grid item lg={9} xs={7} style={{ padding: "15px", fontSize: "20px" }}>
                                    Card view
                                    <Switch
                                        checked={viewMode}
                                        onChange={handleChange}
                                        inputProps={{ "aria-label": "controlled" }}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item lg={3} xs={5} style={{ width: "120px", padding: "15px" }}>
                                    <FormControl fullWidth variant="outlined" size="small" style={{ float: "right" }}>
                                        <Select
                                            value={tag}
                                            onChange={(event) => {
                                                setTag(event.target.value);
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value="">
                                                <em>All</em>
                                            </MenuItem>
                                            <MenuItem value="nft">Nft</MenuItem>
                                            <MenuItem value="amount">Amount</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Card>
                        {!viewMode ? (
                            <CustomTable
                                title="Transactions"
                                columns={[
                                    // {
                                    //     title: "Actions",
                                    //     render: (rowData) => (
                                    //         <CustomTextButton
                                    //             title="open"
                                    //             otherProps={{ size: "sm" }}
                                    //             // onClick={() => history.push(`/app/nft/${rowData._id}`)}
                                    //         />
                                    //     ),
                                    // },
                                    { title: "From", field: "from" },
                                    { title: "To", field: "to" },
                                    { title: "NFT ID", field: "nft_id" },
                                    {
                                        title: "Amount",
                                        render: (rowData) => <>{rowData.amount} SOL</>,
                                    },
                                    { title: "Type", field: "type" },
                                    { title: "Status", field: "status" },
                                ]}
                                data={txnsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        ) : (
                            <TxnsCard
                                data={txnsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default TxnsList;
