import React from "react";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@material-ui/core";
import CustomTextButton from "../../components/Custom/Buttons/TextButton";

import { deleteAnNft } from "../../store/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDialogBox = ({ open, handleClose, nftId, dispatch }) => {
    const handleDeleteNft = () => {
        handleClose();
        dispatch(deleteAnNft({ nftId: nftId }));
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Are you sure want to delete this artwork?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    The changes cannot be undone after clicking on the yes button. The artwork will be removed permanently
                    from your account.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomTextButton title="No" color="danger" onClick={handleClose} />
                <CustomTextButton title="Yes" color="success" onClick={() => handleDeleteNft()} />
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialogBox;
