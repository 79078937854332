export const FETCH_ALL_TXNS = `query ($pageNumber: Int, $pageSize: Int) {
    list_all_transactions(page_number: $pageNumber, page_size: $pageSize) {
      message
      data {
        _id
        txn_id
        type
        tag
        nft_id
        status
        amount
        to
        from
      }
      hasMore
    }
  }
  `;
