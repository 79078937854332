import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { store, persistor } from "./store/store";
import Main from "./Main";

const App = () => {
    const lightTheme = createTheme({
        palette: {
            type: "light",
            primary: {
                light: "#f79e20",
                main: "#F9CA26",
                dark: "#004d40",
            },
        },
    });
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={lightTheme}>
                    <Main />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
